<template>
  <div class="kt-grid kt-grid--ver kt-grid--root">
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v4"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <div class="kt-error_container">
        <h1 class="kt-error_number">403</h1>
        <p class="kt-error_title">ERROR</p>
        <p class="kt-error_description">You don't have permission</p>
        <b-button class="kt-error_button btn-success" @click="backHome">
          <i class="flaticon2-left-arrow-1"></i>Return Home
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/error/error-4.scss';
</style>

<script>
import router from '@/router.js';
export default {
  name: 'Error-4',
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + 'assets/media/error/bg4.jpg';
    }
  },
  methods: {
    backHome() {
      router.replace('dashboard');
    }
  }
};
</script>
